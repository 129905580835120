// Remove Focus Boxes
select:focus {
  outline: $select-focus;
}

form{
  .form_right{
    height: auto!important;
  }
}

button:focus {
  outline: none;
  background-color: $button-background-focus;
}

label {
  font-size: $label-font-size;
  color: #192728;
  text-align: left!important;
  width:100% !important;
  margin-bottom:5px!important;
  &.noFloatingLabel{
    display: block;
    padding:10px 5px!important;
  }
}

form.generateur label{
  padding:5px 5px 10px!important;
}

.row form.generateur .submit{
  margin-left:0px !important;
}

.rappel_telephonique form.generateur .submit{
  margin-left:0px!important;
}

.fancybox-inner{
  height: 550px!important;
}

// ==================== Date

#ui-datepicker-div{

  .ui-widget-header{
   border:1px solid #{$secondary-color};
    background:#{$secondary-color};
  }


}



@import 'input-fields';
//@import 'radio-buttons';
//@import 'checkboxes';
@import 'switches';
@import 'select';
@import 'file-input';
@import 'range';
