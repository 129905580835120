.flex-row {
    display: flex;
    flex-wrap: wrap;
    margin: {
        left: (-1 * $gutter-width / 2);
        right: (-1 * $gutter-width / 2);
    }

    &.vertical {
        flex-direction: column;
        .col {
            padding: $gutter-width / 2;
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    // align-items
    // ==========================================
    &.s-align-items-center {
        align-items: center;
    }
    &.m-align-items-center {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            align-items: center;
        }
    }
    &.l-align-items-center {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            align-items: center;
        }
    }
    &.xl-align-items-center {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            align-items: center;
        }
    }
    
    &.s-align-items-stretch {
        align-items: stretch;
    }
    &.m-align-items-stretch {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            align-items: stretch;
        }
    }
    &.l-align-items-stretch {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            align-items: stretch;
        }
    }
    &.xl-align-items-stretch {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            align-items: stretch;
        }
    }

    &.s-align-items-flex-end {
        align-items: flex-end;
    }
    &.m-align-items-flex-end {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            align-items: flex-end;
        }
    }
    &.l-align-items-flex-end {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            align-items: flex-end;
        }
    }
    &.xl-align-items-flex-end {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            align-items: flex-end;
        }
    }

    // justify-content
    //===========================================
    &.s-justify-content-center {
        justify-content: center;
    }
    &.m-justify-content-center {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            justify-content: center;
        }
    }
    &.l-justify-content-center {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            justify-content: center;
        }
    }
    &.xl-justify-content-center {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            justify-content: center;
        }
    }

    &.s-justify-content-space-between {
        justify-content: space-between;
    }
    &.m-justify-content-space-between {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            justify-content: space-between;
        }
    }
    &.l-justify-content-space-between {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            justify-content: space-between;
        }
    }
    &.xl-justify-content-space-between {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            justify-content: space-between;
        }
    }

    &.s-justify-content-space-around {
        justify-content: space-around;
    }
    &.m-justify-content-space-around {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            justify-content: space-around;
        }
    }
    &.l-justify-content-space-around {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            justify-content: space-around;
        }
    }
    &.xl-justify-content-space-around {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            justify-content: space-around;
        }
    }

    &.s-justify-content-flex-end {
        justify-content: flex-end;
    }
    &.m-justify-content-flex-end {
        display: block;
        @media #{$medium-and-up} {
            display: flex !important;
            justify-content: flex-end;
        }
    }
    &.l-justify-content-flex-end {
        display: block;
        @media #{$large-and-up} {
            display: flex !important;
            justify-content: flex-end;
        }
    }
    &.xl-justify-content-flex-end {
        display: block;
        @media #{$extra-large-and-up} {
            display: flex !important;
            justify-content: flex-end;
        }
    }

    // Colonnes
    //===========================================
    .col {
        padding: {
            right: $gutter-width / 2;
            left: $gutter-width / 2;
        }
        > .flex-row {
            height: 100%;
        }
    }

    @media #{$small-and-down} {
        .col {
            padding: $gutter-width / 2;
        }
        flex-wrap: wrap;
    }

    $i: 1;

    @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");

        .s#{$i} {
            width: $perc;
            @include reset-offset;
        }

        $i: $i+1;
    }

    $i: 1;

    @while $i <=$num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("s", $i, $perc);
        $i: $i + 1;
    }

    @media #{$medium-and-up} {

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");

            .m#{$i} {
                width: $perc;
                @include reset-offset;
            }

            $i: $i+1
        }

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("m", $i, $perc);
            $i: $i + 1;
        }
    }

    @media #{$large-and-up} {

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");

            .l#{$i} {
                width: $perc;
                @include reset-offset;
            }

            $i: $i+1;
        }

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("l", $i, $perc);
            $i: $i + 1;
        }
    }

    @media #{$extra-large-and-up} {

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");

            .xl#{$i} {
                width: $perc;
                @include reset-offset;
            }

            $i: $i+1;
        }

        $i: 1;

        @while $i <=$num-cols {
            $perc: unquote((100 / ($num-cols / $i)) + "%");
            @include grid-classes("xl", $i, $perc);
            $i: $i + 1;
        }
    }
}